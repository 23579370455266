div.backdrop div.container {
    margin: auto;
}

div.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999;
}
div.backdrop.visible {
    display: flex;
}

div.backdrop.invisible {
    display: none;
}

div.backdrop div.container .entry-bookend-progress-container  {
    width: 400px;
}

.footer-modal{
    width: 100%;
    margin: 0;
}