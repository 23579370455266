
div.backdrop-loader{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8); 
    z-index: 99999;
}

div.container-loader {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px;
}

.container-loader-mensagem{
    width: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: 100px;
}

@keyframes animation {
    0% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -105;
    }

    50% {
        stroke-dasharray: 80 10;
        stroke-dashoffset: -160;
    }

    100% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -300;
    }
}

circle.circle-loader{
    fill:transparent;
    stroke:#0080FF;
    stroke-width: 7px;
    stroke-linecap: round;
    filter:url(#shadow-loader);
    transform-origin: center;
    animation-name: animation;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier;
    animation-iteration-count: infinite;
}

img.img-loader{
    width: 120px; 
    position: absolute;
    left: 40px;
    top: 40px;
}