@font-face {
    font-family: "HKGrotesk-Regular";
    src: url("./fonts/HKGrotesk-Regular.otf") format("opentype");
}

@font-face {
    font-family: "HKGrotesk-Bold";
    src: url("./fonts/HKGrotesk-Bold.otf") format("opentype");
}

@font-face {
    font-family: "HKGrotesk-SemiBold";
    src: url("./fonts/HKGrotesk-SemiBold.otf") format("opentype");
}


.body {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.hay-logo {
    height: 100px;
    margin-right: 20px;
}

.entry-bookend-progress-container.header {
    flex-wrap: wrap;
}

.entry-bookend-progress-container .progress-item.progress-step .progress-index.larguinho {
    width: auto;
    border-radius: 5px;
    padding: 0 10px;
    white-space: nowrap;
    margin: 0 5px;
    flex-grow: 1;
}



body {
    background-color: #ccc;
    font-family: "HKGrotesk-Regular", system-ui;
    letter-spacing: 1px;
    font-weight: 700;
    color: #0775a9;
    margin-right: 10px;
    margin-left: 10px;
    margin: 0;
}

ul {
    margin: 0px 10px 10px 10px;
}


.controller-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    width: calc(100% - 40px);
    border-radius: 5px;
    list-style: none;
    align-items: center;
    background: transparent;
    margin-bottom: -20px;
    z-index: 1;
    font-size: smaller;
    position: relative;
}

.controller-header .header {
    padding: 3px 5px;
    background-color: #0080FF;
    border-radius: 5px;
    border-top: 0px;
    border-left: 0px;
    border-bottom: 2px solid #070067;
    border-right: 2px solid #070067;
    margin: 5px;
    color: #005882;
    font-family: "HKGrotesk-Regular", system-ui;
    letter-spacing: 1px;
    font-weight: 600;
}

.subtitulo-acoes {
    padding: 0 0;
    margin: 0;
    font-family: "HKGrotesk-Regular", system-ui;
    letter-spacing: 1px;
    font-weight: 600;
}

.controller-header .right-btn-group {
    position: absolute;
    right: 0
}


.test-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.test-container .controller {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.test-container .controller #next {
    padding: 10px 20px;
    background-color: #47BDF9;
    border-radius: 5px;
    border-top: 0px;
    border-left: 0px;
    border-bottom: 2px solid #070067;
    border-right: 2px solid #070067;
    margin: 10px;
    color: #005882;
    font-family: "HKGrotesk-Regular", system-ui;
    letter-spacing: 1px;
    font-weight: 700;
}

.test-container .controller #next:active {
    border: 0px;
}

.entry-bookend-progress-container {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    width: calc(100%);
    background-color: #f5f5f5;
    border-radius: 30px;
    list-style: none;
    padding: 20px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    align-items: center;
}

.menu-split {
    width: 50%;
    margin: 10px;
}

.entry-bookend-progress-container .progress-item {
    margin: 2px;
}

.entry-bookend-progress-container .progress-item.progress-step {
    display: flex;
    flex: 1;
}

.entry-bookend-progress-container .progress-item.progress-step .progress-index {
    margin: auto;
    line-height: 40px;

    border-radius: 5px;
    /*background-color: #47bdf9;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border: 3px solid #47bdf9;*/
    transition: all 0.2s linear;
    flex: 1;
}

.break {
    flex-basis: 100%;
    height: 0;
    margin: 0;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.entry-bookend-progress-container .progress-item.progress-step .progress-index.numero {
    width: 100px;
    height: 80px;
    margin: auto;
    line-height: 40px;
    border-radius: 5px;
    color: #0080FF;
    /*box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border: 3px solid #47bdf9;*/
    transition: all 0.2s linear;
}

.entry-bookend-progress-container .progress-item.progress-step .progress-index .indicador {
    width: 100px;
    margin: auto;
    line-height: 40px;

    border-radius: 40%;
    /*background-color: #47bdf9;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border: 3px solid #47bdf9;*/
    transition: all 0.2s linear;
}

.entry-bookend-progress-container .progress-item.progress-step .progress-index .index {
    color: #E0EFFC;
    font-weight: bold;
}

.entry-bookend-progress-container .progress-item.progress-step .progress-index .index.menu {
    padding-top: 10px;
}

.entry-bookend-progress-container .progress-item.progress-step.current .progress-index {
    /*border-color: #039be4;*/
    transform: scale(1.1);
}

.entry-bookend-progress-container .progress-item.progress-step.current .progress-index::before {
    font-size: 24px;
}

.entry-bookend-progress-container .progress-item.progress-step.active .progress-index {
    /* background-color: #039be4;*/
}

.entry-bookend-progress-container .progress-item.progress-step.active .progress-index .index {
    display: none;
    padding-top: 10px;
}

.entry-bookend-progress-container .progress-item.progress-step.active .progress-index::before {
    content: "";
    color: #fff;
    font-size: 36px;
    opacity: 0.6;
    font-weight: normal;
}

.entry-bookend-progress-container .progress-item.item-divider {
    width: 100%;
    /* max-width: 100px; */
    position: relative;
    display: flex;
}

.entry-bookend-progress-container .progress-item.item-divider .line-divider {
    align-self: center;
    height: 5px;
    width: 100%;
    background-color: #47bdf9;
    opacity: 0.3;
    border-radius: 5px;
    transition: opacity 0.3s ease-in;
}

.entry-bookend-progress-container .progress-item.item-divider .line-divider::before {
    content: "";
    display: block;
    border-radius: 5px;
    height: 5px;
    background-color: #039be4;
    width: 0px;
    transition: width 0.3s linear;
}

.entry-bookend-progress-container .progress-item.item-divider.active .line-divider {
    opacity: 1;
}

.entry-bookend-progress-container .progress-item.item-divider.active .line-divider::before {
    width: 100%;
}

.entry-bookend-progress-container .progress-item.item-divider .line-divider.progresso {
    position: absolute;
    background: #039BE4;
    animation: borealisBar 1s linear infinite;
    opacity: 1;
    top: 0;
    border-radius: 5px;
}

.entry-bookend-progress-container .progress-item.item-divider .container-progresso {
    position: absolute;
    width: 0%;
    opacity: 1;
    border-radius: 5px;
    height: 5px;
    transition: width 0.3s ease-in;
}

.entry-bookend-progress-container .progress-item.item-divider .container-progresso.ativar {
    opacity: 1;
}

@keyframes borealisBar {
    0% {
        left: 0%;
        right: 100%;
        width: 0%;
    }

    10% {
        left: 0%;
        right: 75%;
        width: 25%;
    }

    90% {
        right: 0%;
        left: 75%;
        width: 25%;
    }

    100% {
        left: 100%;
        right: 0%;
        width: 0%;
    }
}

.linha-contrato {
    margin: 0;
}

.fatura-card {
    flex-direction: column;
}


.fundo-inicial {
    height: 100%;
    background-color: #0080ff;
    position: fixed;
    z-index: 99999;
    border-radius: 0;
    flex-direction: column;
}

.fundo-contratos {
    height: 100%;
    background-color: #E0EFFC;
    position: fixed;
    z-index: 99999;
    border-radius: 0;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
}

.boas-vindas {
    color: #fff;
    font-size: x-large;
}

.nome-cliente{
    color: #fff;
    font-size: x-large;
    margin: 10px;
    padding-top: 10px;
    text-align: left;
    font-weight: 400;
}

.menu-inicial{
    color: #fff;
    font-size: x-large;
    margin: 10px;
    padding: 50px 30px;
    text-align: left;
    font-weight: 400;
    width: 230px;
}
.endereco-cliente{
    color: #fff;
    font-size: large;
    margin: 0 10px;
    text-align: left;
}
.labels{
    text-align: left;
    padding: 0 10px;
    color: white;
}

.label-contrato{    
    font-size: medium;
    background-color: #00ADFF;
    border-radius: 30px;
    padding: 10px 15px;
    font-weight: 300;
}
.label-situacao{    
    margin-left: 10px;
    font-size: medium;
    background-color: #DF1E28;
    border-radius: 30px;
    padding: 10px 15px;
    font-weight: 300;
}
.label-situacao-fatura{
    margin-left: 10px;
    font-size: medium;
    background-color: #DF1E28;
    border-radius: 30px;
    padding: 10px 15px;
    font-weight: 300;
    color: white;
}
.valor-fatura{
    color: #235E8E;
    margin-bottom: 6px;
}
.subdivisao-faturas{
    flex-direction: row;
    display: flex;
    flex-grow: 1;    
    width: 100%;
}
.primeira-metade {
    margin-bottom: 20px;
    border-bottom: 2px solid #FFFFFF;
    padding-bottom: 14px;
}

.label-numerodocumento{
    font-size: small;
    border: 2px solid #29B9FE;
    border-radius: 30px;
    padding: 10px 15px;
}

.selecao-contrato{
    color: #2E5E82;
    font-size: x-large;
}

.topo-navegacao {
    margin:0;
    position: relative;
    background-color: #0080ff;
    height: 15%;
    width: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
.botao-contrato{
    align-content: flex-end;
    height: 100%;
    background-color: #00ADFF;
    color: white;
    border-radius: 30px;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
}
.apagadim{
    opacity: 0.4;
}

.img-topo-navegacao {
    position: absolute;
    left: 50px;
    height: 80%;
}

.voltar-etapa{
    border: 2px solid;
    padding: 12px 30px;
    border-radius: 20px;
    border-color: #ADCAE0;
    margin-left: 40px;
    margin-right: 10px;
    cursor: pointer;
}

.chevron-breadcrumb{
    color: #ADCAE0;
}

.circulo-breadcrumb{
    color: #0080FF !important;
    margin: 0;
}

.numero-breadcrumb{
    width: 50px !important;;
}

.nome-usuario-navegacao{
    margin-top: 50px;
    color: #fff;
    font-size: x-large;
    text-align: right;
    padding-right: 50px;
    margin-bottom: 5px;
}
.texto-breadcrumb{
    font-size: small;
}
.breadcrumb{
    width: calc(100% - 120px);
    margin: 0;
    box-shadow: none !important;
    background-color: transparent;
    padding: 0;
}

.lista-contratos{
    box-shadow: none !important;
}
.ano-mes{
    flex-direction: row;
    display: flex;
    align-items: baseline;
}
.ano-fatura{
    color: #2676C1;
    font-size: small;
}
.mes-fatura{
    color: #2676C1;
    font-size: xx-large;
    margin-left: 10px;
    margin-bottom: 0;
}

.lista-contratos span.interior, .lista-contratos a.interior{
    padding-bottom: 0;
    padding-top: 0;
    padding-right: 0;
    width: "calc(100%)";
    font-size: xx-large;
    font-weight: 900;
    background-color: #0080FF;
    flex-direction: row;
    margin: 0 0 10px 0;
    position: relative;
}

.lista-contratos a.interior.menu{
    padding: 0;
    width: 100%;
    text-decoration: none;
}

.lista-contratos a.interior.menu svg{
    vertical-align: sub;
}


.lista-faturas span.interior{
    padding: 0;
    width: "calc(100%)";
    font-size: xx-large;
    font-weight: 900;
    background-color: #E0EFFC;
    flex-direction: row;
    margin: 0 0 10px 0;
    position: relative;
    flex-direction: column;
}

.cpf-usuario-navegacao{
    color: #fff;
    font-size: large;
    text-align: right;
    padding-right: 50px;
    margin-top: 5px;
}

.botao-autoatendimento {
    color: #fff;
    border-radius: 50%;
    border-radius: 30px;
    padding: 20px;
    font-size: x-large;
    margin-top: 50px;
    cursor: pointer;
}

.botao-autoatendimento.primario {
    background-color: #29DB4A;
}

.botao-autoatendimento.secundario {
    background-color: #00ADFF;
}

.botao-autoatendimento.terciario {
    background-color: #e40c34;
}
.botao-cartao{
    color: #fff;
    border-radius: 50%;
    border-radius: 30px;
    padding: 20px;
    font-size: small;
    cursor: pointer;
}

.botao-pix{
    margin-left: 10px;
    color: #fff;
    border-radius: 50%;
    border-radius: 30px;
    padding: 20px;
    font-size: small;
    cursor: pointer;
}

.Toastify__toast-container {
    z-index: 999999 !important;
}